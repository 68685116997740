// Fonts

// Colors
export const darkBlue = '#1F3D7B';
export const lightBlue = '#0183ff';

export const darkBlue2 = '#00305E';
export const lightBlue2 = '#7CBDFC';

export const gold = '#FFA624';
export const lightGold = '#FFE5A9';

export const darkGrey = '#333E48';
export const lightGrey = '#BBC1C7';

export const lightGreen = '#6FC102';

export const orange = '#FF7600';
