import React from 'react';

import {
  BrowserRouter,
} from 'react-router-dom';
import styled from 'styled-components';

import Page from '~/Page';

const PageContainer = styled.div`
  background-color: white;
  overflow-x: hidden;
`;

function App() {
  return (
    <PageContainer>
      <BrowserRouter>
        <Page />
      </BrowserRouter>
    </PageContainer>
  );
}

export default App;
