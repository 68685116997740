import { pathOr } from 'ramda';
import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import Alert from '~/components/header/Alert';
import Links from '~/components/header/Links';
import LinksMobile from '~/components/header/LinksMobile';
import Login from '~/components/header/Login';
import QueryLoading from '~/components/QueryLoading';
import useGetPage from '~/lib/useGetPage';
import useStyle from '~/lib/useStyle';
import { darkBlue } from '~/styles';
import { contentUrl } from '~/lib/server';

const WrapperRow = styled(Row)`
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: calc(-50vw + 50%);
    width: 100vw;
    background-color: white;
    border-top: 4px solid ${darkBlue};
    box-shadow: 0 2px 10px #cdcfd1;
  }
  position: relative;
  z-index: 2;
  color: ${darkBlue};
  background-color: white;
`;

const WrapperCol = styled(Col)`
  z-index: 3;
`;

const StyledRow = styled(Row)`
  margin: 0 -${({ $pageMargin }) => $pageMargin};
  padding: 0 ${({ $pageMargin }) => $pageMargin};
  margin-top: 4px;
  color: ${darkBlue};
  background-color: white;
`;

function DesktopHeader({ logo }) {
  const { pageMargin } = useStyle();
  return (
    <WrapperRow $pageMargin={pageMargin} align="middle">
      <WrapperCol span={24}>
        <StyledRow $pageMargin={pageMargin} align="middle">
          <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            {logo && <img src={contentUrl(logo.url)} alt="Logo" />}
          </Col>
          <Col xs={12} sm={12} md={12} lg={18} xl={18} xxl={18}>
            <Row align="middle" justify="end">
              <Col xs={0} sm={0} md={0} lg={18} xl={18} xxl={18}>
                <Links />
              </Col>
              <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
                <Login />
              </Col>
              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <Row align="middle" justify="end">
                  <LinksMobile />
                </Row>
              </Col>
            </Row>
          </Col>
        </StyledRow>
      </WrapperCol>
    </WrapperRow>
  );
}

export default function Header() {
  const contents = useGetPage('/header');
  const { alert, alertUrl, logo = {} } = pathOr([], ['data'], contents);
  return (
    <QueryLoading query={contents}>
      <Alert alert={alert} alertUrl={alertUrl} />
      <DesktopHeader logo={logo} />
    </QueryLoading>
  );
}
