import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      render: props.render,
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: true };
  }

  render() {
    const {
      error,
      hasError,
      render,
    } = this.state;
    if (hasError) {
      if (render) {
        return render(error);
      }
      return <p>Loading failed! Please reload.</p>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
