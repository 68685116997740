import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorBoundary from '~/components/ErrorBoundry';

const routes = [
  {
    path: '/404',
    component: lazy(() => import('~/pages/404')),
  },
  {
    path: '/500',
    component: lazy(() => import('~/pages/500')),
  },
  {
    path: '/privacy',
    component: lazy(() => import('~/pages/Privacy')),
  },
  {
    path: '/contact',
    component: lazy(() => import('~/pages/Contact')),
  },
  {
    path: '/features',
    component: lazy(() => import('~/pages/Features')),
  },
  {
    path: '/login',
    component: lazy(() => import('~/pages/Login')),
  },
  {
    path: '/',
    component: lazy(() => import('~/pages/Home')),
  },
];

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spin />}>
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          ))}
          <Route
            path="*"
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: '/404',
                  state: { from: location },
                }}
              />
            )}
          />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}
