import { find } from 'ramda';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;
const pageMargins = [
  ['xxl', '70px'],
  ['xl', '70px'],
  ['lg', '70px'],
  ['md', '16px'],
  ['sm', '16px'],
  ['xs', '16px'],
];

export default function useStyle() {
  const breakpoints = useBreakpoint();

  const current = find(([v]) => breakpoints[v], pageMargins) ?? pageMargins[2];
  return {
    ...breakpoints,
    breakpoint: current[0],
    pageMargin: current[1],
  };
}
