import React from 'react';

import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  text-align: center;
`;

export default function Login() {

  function redirectToLoginPage() {
    window.location.href = "https://eprlive.com/login.aspx"
  }

  return (
    <>
      <StyledButton type="primary" onClick={redirectToLoginPage}>
        EPRLive Login
      </StyledButton>
    </>
  );
}
