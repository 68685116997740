import { pathOr } from 'ramda';
import React from 'react';

import { Button } from 'antd';
import styled from 'styled-components';

import {
  darkBlue,
  darkGrey,
  gold,
  lightBlue,
  lightGold,
  lightGrey,
  orange,
} from '~/styles';

const backgroundColors = {
  blue: darkBlue,
  orange: gold,
  white: 'white',
};

const colors = {
  blue: 'white',
  orange: darkBlue,
  white: darkBlue,
};

const backgroundColorsHover = {
  blue: lightBlue,
  orange: lightGold,
  white: lightGrey,
};

const backgroundColorsClick = {
  blue: lightBlue,
  orange,
  white: darkGrey,
};

const StyledButton = styled(Button)`
  && {
    width: 100%;
    padding: 9px 48px;
    background-color: ${(props) => pathOr('white', [props.$buttonColor], backgroundColors)};
    color: ${(props) => pathOr(darkBlue, [props.$buttonColor], colors)};
  }
  &:hover {
    font-size: 15px;
    font-weight: 800;
    background-color: ${(props) => pathOr('white', [props.$buttonColor], backgroundColorsHover)};
    color: ${(props) => pathOr(darkBlue, [props.$buttonColor], colors)};
  }
  &:active {
    font-size: 15px;
    font-weight: 800;
    background-color: ${(props) => pathOr('white', [props.$buttonColor], backgroundColorsClick)};
    color: ${(props) => pathOr(darkBlue, [props.$buttonColor], colors)};
  }
`;

export default function Link({
  className, color, title, url,
}) {
  return (
    <StyledButton $buttonColor={color} className={className} href={url}>
      {title}
    </StyledButton>
  );
}
