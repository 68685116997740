import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import { darkBlue, lightBlue } from '~/styles';

const StyledNavLink = styled(NavLink)`
  color: ${darkBlue};
  font-size: 15px;
  font-weight: 800;
  line-height: 23px;
  padding: 0 16px;
  &.active, &:hover {
    color: ${lightBlue};
    text-decoration: none;
  }
`;

export default function Links() {
  return (
    <Row justify="end">
      <Col>
        <StyledNavLink to="/" exact>Home</StyledNavLink>
        <StyledNavLink to="/features" exact>Features</StyledNavLink>
        <StyledNavLink to="/contact" exact>Contact</StyledNavLink>
      </Col>
    </Row>
  );
}
