import Axios from 'axios';

const { NODE_ENV: env } = process.env;
const baseURL = env === 'development' ? 'https://mbackend.eprlive.com' : '';
const server = Axios.create({
  baseURL,
  validateStatus: (status) => status >= 200 && status < 300,
});

export function contentUrl(path) {
  return `${baseURL}${path}`;
}

export default server;
