import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row } from 'antd';
import styled from 'styled-components';
import { lightBlue } from '~/styles';

const StyledNavLink = styled(NavLink)`
  color: white;
  font-size: 15px;
  font-weight: 800;
  line-height: 23px;
  padding: 0 16px;
  border-right: 2px solid white;
  &.active, &:hover {
    color: ${lightBlue};
    border-bottom: 2px solid transparent;
    text-decoration: none;
  }
  :first-of-type {
    padding-left: 0px;
  }
  :last-of-type {
    border-right: none;
  }
`;

export default function Links() {
  return (
    <Row>
      <StyledNavLink to="/" exact>Home</StyledNavLink>
      <StyledNavLink to="/features" exact>Features</StyledNavLink>
      <StyledNavLink to="/privacy" exact>Privacy</StyledNavLink>
      <StyledNavLink to="/contact" exact>Contact</StyledNavLink>
    </Row>
  );
}
