import preval from 'preval.macro';
import { map, pathOr } from 'ramda';

import React from 'react';
import { Col, Input, Row, Space, Typography } from 'antd';
import styled from 'styled-components';
import { version } from 'package.json';

import dotBlue from '~/assets/dot-blue.png';
import emailImage from '~/assets/email.svg';
import phoneImage from '~/assets/phone.svg';

import QueryLoading from '~/components/QueryLoading';
import useGetPage from '~/lib/useGetPage';

import Links from '~/components/footer/Links';
import Link from '~/components/page/sections/Link';
import useStyle from '~/lib/useStyle';

import { darkBlue, darkBlue2, gold, lightGold, orange } from '~/styles';

const { Title, Text } = Typography;

const StyledRow = styled(Row)`
  position: relative;
  background: linear-gradient(90deg, #0052a0, #4a9ce9 100%);
  margin: 0 -${({ $pageMargin }) => $pageMargin};
  padding: 88px ${({ $pageMargin }) => $pageMargin};
  z-index: 1;

  h3 {
    color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: calc(-50vw + 50%);
    width: 100vw;
    background: linear-gradient(90deg, #0052a0, #4a9ce9 100%);
  }
`;

const StyledRowBottom = styled(Row)`
  position: relative;
  background: ${darkBlue2};
  margin: 0 -${({ $pageMargin }) => $pageMargin};
  padding: 40px ${({ $pageMargin }) => $pageMargin};
  color: white;
  font-size: 15px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: calc(-50vw + 50%);
    width: 100vw;
    background: ${darkBlue2};
  }
`;

const NewsletterRow = styled(Row)`
  margin: 16px 0px;
`;

const NewsletterButtonCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledImg = styled.img`
  width: 40px;
  height: 40px;
`;

const StyledTitle = styled(Title)`
  && {
    border-left: 4px solid ${gold};
    padding: 0 25px;
    color: white;
    white-space: pre-wrap;
    background: transparent;
  }
`;

const SignUpButton = styled(Input)`
  && {
    height: 36px;
    font-size: 15px;
    font-weight: 800;
    padding: 9px 16px;
    line-height: 18px;
    background-color: ${gold};
    color: ${darkBlue};
    border: none;
  }
  &:hover {
    font-size: 15px;
    font-weight: 800;
    background-color: ${orange};
    color: ${darkBlue};
  }
  &:active {
    font-size: 15px;
    font-weight: 800;
    background-color: ${lightGold};
    color: ${darkBlue};
  }
`;

const StyledText = styled(Text)`
  color: white;
`;

const StyledTop = styled.div`
  display: flex;
  width: 100%;
  height: 21px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: calc(-50vw + 50%);
    width: 100vw;
    background-image: url(${dotBlue});
    background-repeat: repeat;
    background-size: 10px 10px;
  }
`;

function getBranchFromRef(ref = 'unknown') {
  return ref.split('/').pop();
}

const branch = getBranchFromRef(process.env.GITHUB_REF);
const buildTimestamp = preval`module.exports = new Date().toString();`;

export default function Footer() {
  const { pageMargin } = useStyle();
  const contents = useGetPage('/footer');
  const {
    title = '',
    emailAddress,
    phoneNumber,
    links = [],
  } = pathOr([], ['data'], contents);
  const versionString = `${version} ${buildTimestamp} ${branch}`;
  return (
    <QueryLoading query={contents}>
      <StyledTop $pageMargin={pageMargin} />
      <StyledRow $pageMargin={pageMargin}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <StyledTitle level={2}>{title}</StyledTitle>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16}>
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
              <Row gutter={[32, 32]}>
                <Col span={4}>
                  <StyledImg src={phoneImage} />
                </Col>
                <Col span={20}>
                  <Title level={3}>{phoneNumber}</Title>
                </Col>
                <Col span={4}>
                  <StyledImg src={emailImage} />
                </Col>
                <Col span={20}>
                  <Title level={3}>
                    <a
                      style={{ color: 'white' }}
                      rel="noreferrer"
                      target="_blank"
                      href={`mailto:${emailAddress}`}
                    >
                      {emailAddress}
                    </a>
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <Space direction="vertical" size={[32]}>
                {map(
                  (link) => (
                    <Link key={link.title} {...link} />
                  ),
                  links,
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </StyledRow>
      <StyledRowBottom $pageMargin={pageMargin}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Links linkStyle="footer" />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <form
            action="https://corelliansoft.us11.list-manage.com/subscribe/post?u=cc8e2764c1785bba13e6ee10e&amp;id=8b13dc2459"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <NewsletterRow gutter={[16, 16]}>
              <Col span={24}>
                <StyledText htmlFor="mce-EMAIL">Newsletter Sign-Up</StyledText>
              </Col>
            </NewsletterRow>
            <Row gutter={[16, 16]} justify="end">
              <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                <Input
                  type="email"
                  defaultValue=""
                  name="EMAIL"
                  className="email"
                  id="mce-EMAIL"
                  required
                  placeholder="E-Mail Address"
                />
              </Col>

              <NewsletterButtonCol xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <SignUpButton
                  type="submit"
                  defaultValue="Sign Up"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                />
              </NewsletterButtonCol>

              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_cc8e2764c1785bba13e6ee10e_8b13dc2459"
                  tabIndex="-1"
                  defaultValue=""
                />
              </div>
            </Row>
          </form>
        </Col>
        <Col span={24}>
          <StyledText title={versionString}>
            © 2021 Corellian Software
          </StyledText>
        </Col>
      </StyledRowBottom>
    </QueryLoading>
  );
}
