import { prop, propOr } from 'ramda';
import React from 'react';

import { Spin } from 'antd';

import styled from 'styled-components';

const StyledSpin = styled(Spin)`
  margin: 25px;
  width: 100%;
  height: 100%;
`;

export default function QueryLoading({ children, query }) {
  const isLoading = prop('isLoading', query);
  const isError = propOr(false, ['isError'], query);

  if (isLoading) {
    return (<StyledSpin />);
  }

  if (isError) {
    return (<div>{query.error}</div>);
  }

  return children;
}
