/* eslint-disable */
import React from 'react';

import { Layout } from 'antd';
import styled from 'styled-components';

import Routes from './Router';
import useStyle from '~/lib/useStyle';
import PageHeader from '~/sections/PageHeader';
import PageFooter from '~/sections/PageFooter';

const { Header, Footer, Content } = Layout;

const StyledLayout = styled(Layout)`
  background: #FFFFFF;
  min-height: 100vh;
  margin: 0 ${({ $pageMargin }) => $pageMargin};


  @media (min-width: 1200px) {
    margin: auto;
    max-width: 1200px;
  }
`;
const StyledHeader = styled(Header)`
  padding: 0;
  height: auto;
  background-color: transparent;
`;
const StyledFooter = styled(Footer)`
  padding: 0;
  background: #FFFFFF;
`;

function Page() {
  const { pageMargin } = useStyle();
  return (
    <StyledLayout $pageMargin={pageMargin}>
      <StyledHeader>
        <PageHeader />
      </StyledHeader>
      <Content>
        <Routes />
      </Content>
      <StyledFooter>
        <PageFooter />
      </StyledFooter>
    </StyledLayout>
  );
}

export default Page;
