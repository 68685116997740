import { isEmpty } from 'ramda';
import React from 'react';
import { Row } from 'antd';

import styled from 'styled-components';
import useStyle from '~/lib/useStyle';
import { gold } from '~/styles';

const StyledRow = styled(Row)`
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: calc(-50vw + 50%);
    width: 100vw;
    background-color: ${gold};
  }
  position: relative;
  z-index: 1;
  margin: 0 -${({ $pageMargin }) => $pageMargin};
  color: white;
  font-size: 15px;
  font-weight: 800;
  line-height: 23px;
  padding: 7px 0;
  a {
    color: white;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export default function Alert({ alert, alertUrl }) {
  const { pageMargin } = useStyle();
  if (isEmpty(alert)) {
    return <></>;
  }
  return (
    <StyledRow $pageMargin={pageMargin} align="middle" justify="center">
      <a target="_blank" rel="noreferrer" href={alertUrl}>
        {alert}
      </a>
    </StyledRow>
  );
}
