import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Button, Popover } from 'antd';
import styled from 'styled-components';

import Login from '~/components/header/Login';
import { darkBlue, lightBlue } from '~/styles';

const StyledButton = styled(Button)`
  width: auto;
  padding: 0;
  -webkit-border-radius: 0px;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  width: 200px;
  margin: 16px 0;
  color: ${darkBlue};
  font-size: 15px;
  font-weight: 800;
  line-height: 23px;
  padding: 0 16px;
  text-align: center;
  &.active, &:hover {
    color: ${lightBlue};
    text-decoration: none;
  }
`;

export default function LinksMobile() {
  const [visible, setVisible] = useState(false);

  function onClick() {
    setVisible(false);
  }

  return (
    <Popover
      content={(
        <>
          <StyledNavLink onClick={onClick} to="/" exact>Home</StyledNavLink>
          <StyledNavLink onClick={onClick} to="/features" exact>Features</StyledNavLink>
          <StyledNavLink onClick={onClick} to="/contact" exact>Contact</StyledNavLink>
          <Login />
        </>
      )}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      placement="bottomRight"
    >
      <StyledButton type="text">
        <MenuOutlined style={{ fontSize: '32px', color: darkBlue }} />
      </StyledButton>
    </Popover>
  );
}
